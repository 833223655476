import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";

// Load CSS
import "antd/dist/antd.css";
import "./styles/customantd.css";
import "./styles/index.css";

// Layout Imports
import LayoutHome from "./layout/publicLayout/LayoutHome";
import LayoutLogin from "./layout/publicLayout/LayoutLogin";
import LayoutSignUp from "./layout/publicLayout/LayoutSignUp";
import ActivateAfterSignUp from "./layout/publicLayout/ActivateAfterSignUp";
import ResetPassword from "./layout/publicLayout/ResetPassword";
import AddPassword from "./layout/publicLayout/AddPassword";
import { LayoutAbout } from "./layout/publicLayout/LayoutAbout";
import LayoutContact from "./layout/publicLayout/LayoutContact";
import { LayoutFAQ } from "./layout/publicLayout/LayoutFAQ";
import LayoutAdminDashboard from "./layout/adminLayout/LayoutAdminDashboard";
import LayoutUserDashboard from "./layout/userLayout/LayoutUserDashboard";
import LayoutTermsOfUse from "./layout/publicLayout/LayoutTermsOfUse";
import LayoutPrivacyPolicy from "./layout/publicLayout/LayoutPrivacyPolicy";
import ChangeLayoutUserDash from "./layout/userLayout/ChangeLayoutUserDash";

// Route Components
import PrivateRouteUserRegister from "./components/common/PrivateRouteUserRegister";
import LoggedInRoute from "./components/common/LoggedInRoute";
import AdminRoute from "./components/common/AdminRoute";
import PublicRoute from "./components/common/PublicRoute";

// Store Preparation
import { prepReduxWithLocalStorage } from "./store/prepStore/prepStoreFromLocal";
import { prepReduxWithApiCallConstants } from "./store/prepStore/prepReduxWithApiCallConstants";

// Constants
import { NEW_LAYOUT_PATH, OLD_LAYOUT_PATH } from "./helpers/Constants/path";

// Analytics
import {
  initializeGA,
  logPageView,
} from "./utils/google_analytics_utils/GoogleAnalytics";

prepReduxWithLocalStorage();
prepReduxWithApiCallConstants();

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    initializeGA();

    // Log initial page view
    const logCurrentPage = () => {
      const url = window.location.href;
      const segments = url.split("/");
      const lastSegment = segments[segments.length - 1];
      logPageView(lastSegment);
    };

    logCurrentPage();

    // Log page views on route change
    const handleRouteChange = () => {
      logCurrentPage();
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId="600793487202-i18ke3h80nprvi2cuffhss7ipdmr0bj0.apps.googleusercontent.com">
      <div>
        <Switch>
          {/* ADMIN ROUTES */}
          <AdminRoute path="/admin" component={LayoutAdminDashboard} />

          {/* PUBLIC ROUTES */}
          <PublicRoute exact path="/" component={LayoutLogin} />
          <PublicRoute path="/about" component={LayoutAbout} />
          <PublicRoute path="/contact" component={LayoutContact} />
          <PublicRoute path="/faq" component={LayoutFAQ} />
          <PublicRoute path="/termsofuse" component={LayoutTermsOfUse} />
          <PublicRoute path="/privacypolicy" component={LayoutPrivacyPolicy} />
          <PublicRoute exact path="/login" component={LayoutLogin} />
          <PublicRoute exact path="/signup" component={LayoutLogin} />

          <PublicRoute path="/resetPassword" component={ResetPassword} />
          <PublicRoute path="/addPassword" component={AddPassword} />

          {/* LOGGED IN USER ROUTES */}
          <LoggedInRoute
            path={OLD_LAYOUT_PATH}
            component={LayoutUserDashboard}
          />
          <LoggedInRoute
            path={NEW_LAYOUT_PATH}
            component={ChangeLayoutUserDash}
          />

          {/* OTHER SECURE ROUTES */}
          <PrivateRouteUserRegister
            exact
            path="/activateAccount"
            component={ActivateAfterSignUp}
          />
        </Switch>
        <ToastContainer style={{ width: "700px" }} />
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
