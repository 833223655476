import {
  resetUserAuth,
  resetUserDetails,
} from "../../store/actions/authAction";
import { MUTATION_END_POINT } from "../Constants";
import { signOutRequest } from "./AxiosUtils";

// import { signOutRequest } from "./axiosUtils/AxiosUtils";
// import { MUTATION_END_POINT } from "./Constants";
// import { resetUserAuth, resetUserDetails } from "../store/actions/authAction";

export const handleLogout = (dispatch, history, appBusySet) => {
  const endPoint = MUTATION_END_POINT.SIGN_OUT;
  const appStateData = { message: "Logging Out Please Wait" };
  appBusySet(appStateData);

  signOutRequest(endPoint)
    .then((response) => {
      // Handle successful response
      if (response?.status === 200) {
        console.log("Response Status:", response.status);
        console.log(
          "Response Code:",
          response?.data?.code || "No code provided"
        );

        // Clear user data
      } else {
        // Handle non-successful status codes
        console.error("Failed to log out. Status:", response?.status);
        console.error(
          "Error Code:",
          response?.data?.code || "No code provided"
        );
      }
    })
    .catch((error) => {
      // Handle network or unexpected errors
      console.error("Error during logout:", error);
    })
    .finally(() => {
      dispatch(resetUserAuth());
      dispatch(resetUserDetails());

      localStorage.removeItem("sayakusertoken");
      history.push("/login");
    });
};
