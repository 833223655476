// src/components/ChangeUserNav.js

import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Menu, Dropdown, Layout } from "antd";
import SayakLogo from "../../images/sayak_logo.png";
import "./dashboard-style.css";
// import { handleLogout } from "../../utils/authUtils"; // Import the handleLogout function
import { CgProfile } from "react-icons/cg";
import { LuUser2 } from "react-icons/lu";
import { IoIosLogOut } from "react-icons/io";
import { handleLogout } from "../../utils/axiosUtils/FunctionUtils";

const { Header } = Layout;

const ChangeUserNav = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubMenuItemClicked = () => {
    props.updateMainNav("0");
  };

  const handleMenuItemClicked = (e) => {
    let token = localStorage.getItem("sayakusertoken");
    if (token) {
      props.updateMenuNav(e.key);
    } else {
      handleLogout(dispatch, history, props.appBusySet); // Use the imported handleLogout function
    }
  };

  const openSurvey = () => {
    window.open("https://forms.gle/eYbM6bLaDjnzWhBD6", "_blank");
  };

  const userDropDownMenu = (
    <div className="user-menu-dropdown">
      <Menu>
        <Menu.Item key="1" onClick={handleSubMenuItemClicked}>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <LuUser2 />
            &nbsp; Profile
          </Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => handleLogout(dispatch, history, props.appBusySet)}
        >
          <IoIosLogOut />
          &nbsp; Logout
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <div className="usernav-container">
      <div>
        <Row
          className="container"
          type="flex"
          justify="space-between"
          align="bottom"
        >
          <Col
            xs={{ span: 0 }}
            sm={{ span: 0 }}
            md={{ span: 0 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <div className="logo-section-changeUserNav">
              <Link to="/">
                <img
                  src={SayakLogo}
                  className="logo-desktop"
                  alt="Sayak Logo"
                />
              </Link>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
          >
            <div className="user-nav-right-container">
              <div className="user-header-layout-up">
                <Row>
                  <Header
                    className="user-header"
                    style={{
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Menu
                      theme=""
                      mode="horizontal"
                      selectedKeys={props.selectedItem}
                      style={{
                        fontSize: "1.3em",
                        backgroundColor: "transparent",
                      }}
                      className="usernav-header-menu"
                    >
                      <Menu.Item key="1" onClick={handleMenuItemClicked}>
                        <span>रोमन नेपाली टाइपिङ </span>
                        <Link to="/typeInNepali" />
                      </Menu.Item>
                      <Menu.Item key="2" onClick={handleMenuItemClicked}>
                        <span>हिज्जे परीक्षण</span>
                        <Link to="/spelling" />
                      </Menu.Item>
                      <Menu.Item key="3" onClick={handleMenuItemClicked}>
                        <span>फन्ट टाइपिङ </span>
                        <Link to="/typeInNepaliTTF" />
                      </Menu.Item>
                      <Menu.Item key="4" onClick={handleMenuItemClicked}>
                        <span>फन्ट रूपान्तरण</span>
                        <Link to="/fontConversion" />
                      </Menu.Item>
                      <Menu.Item key="5" onClick={handleMenuItemClicked}>
                        <span>शब्दकोश </span>
                        <Link to="/dictSearch" />
                      </Menu.Item>
                    </Menu>
                  </Header>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <div className="ms-5">
              <span
                className="survey"
                style={{ color: "#0d6efd", fontSize: "1.3em" }}
                onClick={openSurvey}
              >
                सर्वेक्षण
              </span>
              <span style={{ fontSize: "25px" }}> | </span>
              <span
                className="survey"
                style={{
                  color: "#0d6efd",
                  fontSize: "1.4em",
                  fontWeight: "bold",
                }}
              >
                <Dropdown overlay={userDropDownMenu}>
                  <CgProfile />
                </Dropdown>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChangeUserNav;
