const HOST = window.location.origin;
// const HOST = "http://192.168.1.66:8000";
// 103.198.9.212
const baseUrl = `${HOST}/v1`;
const fontConvertUrl = `${HOST}/v1/font/convert`;

// const baseUrl = "/v1";
// const fontConvertUrl = "/v1/font/convert";

export const url = {
  BASE_END_POINT: baseUrl,
  FONT_CONVERT_END_POINT: fontConvertUrl,
};
