import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Button, Modal } from "antd";
import { MUTATION_END_POINT } from "../../utils/Constants";
import { getValueWithToken } from "../../utils/axiosUtils/AxiosUtils";
import { toast } from "react-toastify";
import { handleLogout } from "../../utils/axiosUtils/FunctionUtils";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetUserAuth,
  resetUserDetails,
} from "../../store/actions/authAction";

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [openServiceUsageModal, setOpenServiceUsageModal] = useState(false);

  useEffect(() => {
    generateUserDetails();
  }, []); // Empty dependency array ensures this runs only once on mount

  const generateUserDetails = async () => {
    const endPoint = MUTATION_END_POINT.GET_USER_PROFILE;

    try {
      setLoadingUserDetails(true);
      const response = await getValueWithToken(endPoint);
      setUserDetails(response);
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.message === "invalid token"
        ) {
          dispatch(resetUserAuth());
          dispatch(resetUserDetails());
          localStorage.removeItem("sayakusertoken");
          history.push("/login");
          toast("Token expired! please login again");
        } else {
          toast(
            "तपाईंको नेटवर्क मा समस्या देखिएकोले यो अनुरोध पुरा हुन सकेन । इन्टरनेट कनेक्सन जाँच गरेर पुन प्रयास गर्नुहोला । "
          );
        }
      }
    } finally {
      setLoadingUserDetails(false);
    }
  };

  const handleCancel = () => {
    setOpenServiceUsageModal(false); // Close the modal
  };

  return (
    <div className="dashboard-user-profile">
      <Card loading={loadingUserDetails} className="card-box-shadow-default">
        <Row gutter={16}>
          <Col span={24}>
            <b>Name:</b>
            <p>{userDetails.fullName || "NA"}</p>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <b>Email:</b>
            <p>{userDetails.email || "NA"}</p>
          </Col>
          <Col span={12}>
            <b>UserName:</b>
            <p>{userDetails.username || "NA"}</p>
          </Col>
        </Row>
      </Card>

      {/* Uncomment the below card if needed for service usage */}
      {/*
      <Card className="card-box-shadow-default">
        <p style={{ fontWeight: "bold", fontSize: "20px" }}>Service Usage</p>
        <Button
          onClick={() => setOpenServiceUsageModal(true)} // Open the modal
          type="primary"
        >
          Usage
        </Button>
      </Card>
      */}

      <Modal
        title="Service Usage"
        open={openServiceUsageModal}
        onCancel={handleCancel}
        footer={null}
      >
        <p>Hello</p>
      </Modal>
    </div>
  );
};

export default UserProfile;
