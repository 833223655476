import React, { Component } from "react";

import { Alert, Spin, Input, Card, Button } from "antd";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";

import { Link } from "react-router-dom";
import LoginWithGoogleAndFacebook from "../../layout/layoutUtils/LoginWithGoogleAndFacebook";

const { TextArea } = Input;

export class SpellingCheck extends Component {
  state = {
    serviceName: "SPELLSUGGESTION",
    loadingContainer: true,
    spellingTextAreaTextCopied: false,

    nepaliTypingDrawerVisible: false,

    serviceTimeOut: false,
  };

  componentDidMount() {
    this.spinnerShow();
    // window.CKEDITOR.replace('editor');
    // window.npspell.ckeditor('editor', {
    // dictionary: window.NP_UTILS.DICTIONARY_LANGUAGE,
    // server: window.NP_UTILS.SERVER_NAME
    // });

    this.beforeLoadingSpelling();

    setTimeout(() => {
      console.log("service usage time expired");
      this.setState({ serviceTimeOut: true });

      if (window.document.querySelector("#cke_editor")) {
        window.document.querySelector("#cke_editor").style.display = "none";
      }
    }, 60000);
  }

  spinnerShow = () => {
    setTimeout(() => {
      this.setState({ loadingContainer: false });
      if (document.querySelector("#spelling-check-bottom-instruction")) {
        document.querySelector(
          "#spelling-check-bottom-instruction"
        ).style.marginTop = "5px";
      }
    }, 3500);
  };

  beforeLoadingSpelling = () => {
    setTimeout(() => {
      // this.setState({ loadingContainer: false }, () => {
      //   console.log("spelling container loaded ");
      // });
      this.loadSpellingEditor();
    }, 500);
  };

  loadSpellingEditor = () => {
    if (document.querySelector("#editor")) {
      window.CKEDITOR.replace("editor");
      window.npspell.ckeditor("editor", {
        dictionary: window.NP_UTILS.DICTIONARY_LANGUAGE,
        server: window.NP_UTILS.SERVER_NAME,
      });
    }
  };

  showSuccessFullyCopied = () => {
    setTimeout(() => {
      this.setState({ spellingTextAreaTextCopied: false });
    }, 500);

    return (
      <span style={{ marginRight: "5px" }}>
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: "1.5em" }}
        />{" "}
        Copied{" "}
      </span>
    );
  };

  handleCopyText = () => {
    let a = window.NP_UTILS.editorSentenceText.trim();
    //console.log(a)
    copy(a, {
      options: {
        format: "text/html",
      },
    });
    this.setState({ spellingTextAreaTextCopied: true });
  };

  // openNepaliTypingDrawer = () => {this.setState({nepaliTypingDrawerVisible: true})}
  // closeNepaliTypingDrawer = () => {this.setState({nepaliTypingDrawerVisible: false})}

  render() {
    return (
      <div>
        {this.state.serviceTimeOut ? (
          <div>
            <h2 style={{ textAlign: "center" }}>
              सेवा  सुचारु गर्न खाता खोल्नुहोस्। यो पूर्ण रुपमा नि:शुल्क छ।
            </h2>
            <Card className="card-with-shadow" style={{ textAlign: "center" }}>
              <LoginWithGoogleAndFacebook />

              {/* <h1 style={{ marginTop: "10px" }}>अथवा</h1>
              <Button
                type="primary"
                shape="round"
                size="large"
                className="btn-orange"
                style={{ marginTop: "10px" }}
              >
                <Link to="/signup">नि:शुल्क खाता बनाउनुहोस्</Link>
              </Button> */}
            </Card>
          </div>
        ) : (
          <div className="spelling-check-container">
            <h1 style={{ textAlign: "center" }}>नेपाली हिज्जे परीक्षण</h1>

            <div className="flex-row-justify-space-between">
              <Alert
                message="हिज्जे परीक्षणको लागि नेपाली युनिकोडमा टाइप गर्नुहोस्।"
                type="info"
                showIcon
              />

              <div
                id="spelling-check-checking-spelling-loading"
                style={{ display: "none" }}
              >
                <span>
                  <LoadingOutlined style={{ fontSize: 24 }} spin />
                  Checking Spelling{" "}
                </span>
              </div>

              <div>
                {this.state.spellingTextAreaTextCopied
                  ? this.showSuccessFullyCopied()
                  : null}
                <Button
                  dashed
                  style={{ marginBottom: "10px" }}
                  onClick={this.handleCopyText}
                >
                  Copy All Text To Clipboard
                </Button>
              </div>
            </div>

            {/* <div>
              <span>Problem in typing Nepali? </span>
              <Button onClick={this.openNepaliTypingDrawer} >Open Nepali Typing</Button>
    
              <NepaliTypingDrawer 
                onClose = {this.closeNepaliTypingDrawer}
                drawerVisible={this.state.nepaliTypingDrawerVisible}
                {...this.props}
              />
            </div> */}

            <Alert
              className="dumpUserSuggestedWord-success"
              message="शब्द सुझाव गर्नुभएकोमा धन्यवाद।"
              description=""
              type="success"
              showIcon
              style={{ marginBottom: "10px", display: "none" }}
            />

            <Alert
              className="dumpUserSuggestedWord-fail"
              message="यो शब्द पहिलेनै सुझाव भएको छ। धन्यवाद।"
              description=""
              type="error"
              showIcon
              style={{ marginBottom: "10px", display: "none" }}
            />

            {this.state.loadingContainer ? (
              <Spin
                tip="Please wait . It normally takes 3-5 seconds."
                style={{
                  height: "45vh",
                  backgroundColor: "rgba(227, 226, 210, 0.28)",
                }}
              >
                <div
                  style={{
                    zIndex: "999",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                ></div>
              </Spin>
            ) : null}

            <div id="serverStatus" style={{ display: "none" }}></div>
            <div id="statusMessage" style={{ display: "none" }}></div>
            <textarea id="editor" style={{ visibility: "hidden" }}>
              Loading....
            </textarea>
            <div id="word-count" style={{ display: "none" }}></div>

            <div
              style={{ marginTop: "45vh" }}
              id="spelling-check-bottom-instruction"
            >
              <Alert
                message="बाकसमा टाइप गर्नुहोस् वा अन्य ठाउँबाट कपी गरेर त्यस बाकसमा पेस्ट गर्नुहोस् । गलत हिज्जे भएका शब्द रातो धर्को लगाउने छ ।"
                description="रातो धर्को लगाएको शब्दलाई राइट क्लिक गर्नुहोस् । उक्त गलत हिज्जे भएको शब्दसँग नजिक हिज्जे मिल्ने शब्दहरू देखिनेछन् । ति शब्दहरूबाट उपयुक्त शब्द छान्नुहोस् ।"
                type="info"
                showIcon
                style={{ marginBottom: "10px", marginTop: "20px" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
